


























































































































import { Vue, Component, Prop } from "vue-property-decorator";
import BaseForm from "../../Base/BaseForm.vue";
import BaseCard from "../../Base/BaseCard.vue";
import BaseDialog from "@/components/Base/BaseDialog.vue";
import { toDate } from "@/helpers/Formatter";

@Component({
    components: { BaseCard, BaseDialog, BaseForm },
})
export default class SharedSettingBankAccount extends Vue {
    @Prop() forms!: any;
    @Prop() data!: any;
    @Prop() modelDialog!: boolean;
    @Prop() loading!: boolean;

    public isEdit: boolean = false;

    public headers: any[] = [
        { text: "Nama Bank", value: "bank_name" },
        { text: "Kode Bank", value: "bank_code" },
        { text: "No. Rekening", value: "account_number" },
        { text: "Atas Nama", value: "owner_name" },
        { text: "", value: "updated_at", align: "right" },
        { text: "", value: "actions", align: "right" },
    ];

    get showDialog() {
        return this.modelDialog;
    }
    set showDialog(newValue: boolean) {
        this.$emit("update:dialog", newValue);
    }

    public showDialogDelete: boolean = false;

    public isFormValid: boolean = false;

    createCard() {
        if (this.isEdit) this.$emit("update:card");
        else this.$emit("add:card");
    }

    public selectedCard: any = null;
    get userCards() {
        return this.$store.state.cards.cards;
    }
    async getUserCards() {
        const userId = this.$store.state.users.me.id;
        const response = await this.$store.dispatch(
            "cards/getUserCards",
            userId
        );
    }

    toDate(date: Date) {
        return toDate(date);
    }

    selectCard(card: any) {
        this.isEdit = true;
        this.isFormValid = true;
        this.$emit("select:card", card);
        this.showDialog = true;
    }

    deleteCard(card: any) {
        this.selectedCard = card;
        this.showDialogDelete = true;
    }

    async confirmDeleteCard() {
        const response = await this.$store.dispatch(
            "cards/deleteCard",
            this.selectedCard
        );

        this.showDialogDelete = false;
        this.getUserCards();
    }

    async mounted() {
        await this.getUserCards();
    }
}
